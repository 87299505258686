import React from "react";
import theme from "theme";
import { Theme, Link, Box, Section, Strong, Span, Em, Text } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override, StackItem, Stack } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"privacypolicy"} />
		<Helmet>
			<title>
				Venta-Trans Europe - Freight Forwarding Company
			</title>
			<meta name={"description"} content={"Venta-Trans Europe is an award-winning freight forwarder serving the freight and logistics needs of companies throughout Europe and CIS since 2010. We work with a large base of clients throughout Europe and in numerous sectors, all requiring the high-quality and low-cost service that we provide."} />
			<meta property={"og:title"} content={"Venta-Trans Europe - Freight Forwarding Company"} />
			<meta property={"og:description"} content={"Venta-Trans Europe is an award-winning freight forwarder serving the freight and logistics needs of companies throughout Europe and CIS since 2010. We work with a large base of clients throughout Europe and in numerous sectors, all requiring the high-quality and low-cost service that we provide."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/og_img.jpg?v=2021-04-21T14:38:42.609Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/favicon-32x32.png?v=2021-04-22T09:26:54.780Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/apple-touch-icon.png?v=2021-04-22T09:27:21.156Z"} />
			<meta name={"robots"} content={"noindex,nofollow"} />
		</Helmet>
		<Section
			background="rgba(255, 255, 255, 0.95) linear-gradient(0deg,rgba(255, 255, 255, 0.2) 0.5%,#ffffff 100%)"
			top={0}
			height="70px"
			lg-height="60px"
			lg-padding="10px 0 10px 0"
			font="--headline1"
			quarkly-title="SectionHeader"
			position="sticky"
			z-index="2"
		>
			<Override slot="SectionContent" lg-height="60px" />
			<Box
				md-flex-direction="row"
				md-display="flex"
				lg-padding="0 0 0 0"
				display="flex"
				padding="12px 0"
				justify-content="space-between"
				align-items="center"
				flex-direction="row"
			>
				<Link
					sm-width="60%"
					width="30%"
					height="80px"
					md-display="inline-block"
					md-padding="0px 10px 0px 0"
					md-width="50%"
					target="_self"
					href="/index"
					background="rgba(0, 0, 0, 0) url(https://uploads.quarkly.io/60000c177dc2c5001e036d79/images/logo_web.svg?v=2021-04-20T09:43:08.919Z) 0% 50% /auto no-repeat scroll padding-box"
					padding="0px 10px 0px 10px"
				/>
				{" "}
			</Box>
		</Section>
		<Section
			padding="30px 0 60px 0"
			sm-padding="40px 0"
			min-height="600px"
			sm-min-height="auto"
			background="#fff"
			display="flex"
			flex-direction="column"
		>
			<Stack color="--grey" font="--base" margin="10px 0 -16px 0px">
				<StackItem display="flex" width="100%" md-width="100%">
					<Override slot="StackItemContent" flex-direction="column" />
					<Text font="--base" md-font="--baseMob" color="--dark" as="h1">
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							This Privacy Policy
describes how your personal information is collected, used, and shared when you
visit or make a purchase from{" "}
							<Link
								text-overflow="clip"
								hyphens="manual"
								href="http://www.ventatrans.com"
								overflow-wrap="normal"
								white-space="normal"
								word-break="normal"
								text-indent="0"
							>
								www.ventatrans.com
							</Link>
							{" "}(the “Site”).
						</Strong>
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Privacy and Cookie Policy
of www.ventatrans.com (further “Application”)
						</Strong>
						<br />
						<br />
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-overflow="clip"
							hyphens="manual"
							font-weight="normal"
							text-indent="0"
						>
							In order to receive
information about your Personal Data, the purposes and the parties the Data is
shared with, contact the Owner.
						</Span>
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Owner and Data Controller
						</Strong>
						:
						<Span
							text-overflow="clip"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							hyphens="manual"
							font-weight="normal"
							overflow-wrap="normal"
						>
							<br />
							Venta - Trans Europe s.r.o.
							<br />
							Vinohradska
2133/138
							<br />
							130
00 Praha 3
							<br />
							Czech
Republic
							<br />
						</Span>
						<br />
						<Strong
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
						>
							Owner contact email:
						</Strong>
						<Span
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							text-indent="0"
							white-space="normal"
							text-overflow="clip"
							hyphens="manual"
						>
							{" "}info@ventatrans.cz
						</Span>
						<br />
						<br />
						<br />
						<Strong
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
						>
							Privacy Policy
						</Strong>
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Types of Data collected
						</Strong>
						<br />
						<Span
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							font-weight="normal"
						>
							When you visit the Site,
we automatically collect certain information about your device, including
information about your web browser, IP address, time zone, and some of the
cookies that are installed on your device. Additionally, as you browse the
Site, we collect information about the individual web pages or products that
you view, what websites or search terms referred you to the Site, and
information about how you interact
						</Span>
						{" "}
						<Span
							font-weight="normal"
							white-space="normal"
							text-indent="0"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							text-overflow="clip"
						>
							with the Site. We refer to this
automatically-collected information as “Device Information.”
							<br />
							<br />
							The Owner collects,
stores, and processes data needed for rendering its services through the
website (user data).
							<br />
							<br />
							The Owner can view data
supplied by users (supplied data) only if this is necessary for rendering
services and the user explicitly approves this use (support, for example).
						</Span>
						<br />
						<br />
						<Span
							font-weight="normal"
							word-break="normal"
							text-indent="0"
							hyphens="manual"
							overflow-wrap="normal"
							white-space="normal"
							text-overflow="clip"
						>
							Complete details on each
type of Personal Data collected are provided in the dedicated sections of this
privacy policy or by specific explanation texts displayed prior to the Data
collection.
							<br />
							<br />
							Personal Data may be
freely provided by the User, or, in case of Usage Data, collected automatically
when using this Application.
						</Span>
						<br />
						<br />
						<Span
							font-weight="normal"
							white-space="normal"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							text-indent="0"
						>
							Unless specified
otherwise, all Data requested by this Application is mandatory and failure to
provide this Data may make it impossible for this Application to provide its
services. In cases where this Application specifically states that some Data is
not mandatory, Users are free not to communicate this Data without consequences
to the availability or the functioning of the Service.
							<br />
							<br />
							Users who are uncertain
about which Personal Data is mandatory are welcome to contact the Owner.
						</Span>
						<br />
						<br />
						<Span
							white-space="normal"
							text-overflow="clip"
							hyphens="manual"
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							text-indent="0"
						>
							Any use of Cookies – or
of other tracking tools – by this Application or by the owners of third-party
services used by this Application serves the purpose of providing the Service
required by the User, in addition to any other purposes described in the
present document and in the Cookie Policy, if available.
							<br />
							{" "}
							<br />
							Users are responsible for
any third-party Personal Data obtained, published or shared through this
Application and confirm that they have the third party's consent to provide the
Data to the Owner.
						</Span>
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Mode and place of
processing the Data
						</Strong>
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Methods of processing
						</Strong>
						<br />
						<Span
							hyphens="manual"
							overflow-wrap="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							font-weight="normal"
							word-break="normal"
						>
							The Owner takes
appropriate security measures to prevent unauthorised access, disclosure,
modification, or unauthorised destruction of the Data.
						</Span>
						<br />
						<br />
						<Span
							text-overflow="clip"
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							hyphens="manual"
						>
							The Data processing is
carried out using computers and/or IT enabled tools, following organisational
procedures and modes strictly related to the purposes indicated. In addition to
the Owner, in some cases, the Data may be accessible to certain types of
persons in charge, involved with the operation of this Application
(administration, sales, marketing, legal, system administration) or external
parties (such as third-party technical service providers, mail carriers,
hosting providers, IT companies, communications agencies) appointed, if
necessary, as Data Processors by the Owner. The updated list of these parties
may be requested from the Owner at any time.
						</Span>
						<br />
						<br />
						<Strong
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
						>
							Legal basis of processing
						</Strong>
						<br />
						<Span
							font-weight="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							hyphens="manual"
							overflow-wrap="normal"
							text-overflow="clip"
						>
							The Owner may process
Personal Data relating to Users if one of the following applies:
							<br />
							<Span
								text-overflow="clip"
								hyphens="manual"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
							>
								•{" "}
							</Span>
							{" "}Users have given their consent
for one or more specific purposes. Note: Under some legislations the Owner may
be allowed to process Personal Data until the User objects to such processing
(“opt-out”), without having to rely on consent or any other of the following
legal bases. This, however, does not apply, whenever the processing of Personal
Data is subject to European data protection law;
							<br />
							<Span
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								overflow-wrap="normal"
								word-break="normal"
							>
								•{" "}
							</Span>
							{" "}Provision of Data is necessary
for the performance of an agreement with the User and/or for any
pre-contractual obligations thereof;
							<br />
							<Span
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								overflow-wrap="normal"
								word-break="normal"
							>
								•{" "}
							</Span>
							Processing is necessary for compliance with a legal obligation to
which the Owner is subject;
						</Span>
						<br />
						<Span
							font-weight="normal"
							word-break="normal"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							white-space="normal"
							text-indent="0"
						>
							<Span
								text-overflow="clip"
								hyphens="manual"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
							>
								•{" "}
							</Span>
							Processing is related to a task that is carried out in the public
interest or in the exercise of official authority vested in the Owner;
							<br />
							<Span
								text-overflow="clip"
								hyphens="manual"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
							>
								•{" "}
							</Span>
							Processing is necessary for the purposes of the legitimate interests
pursued by the Owner or by a third party.
							<br />
							<br />
							In any case, the Owner
will gladly help to clarify the specific legal basis that applies to the
processing, and in particular whether the provision of Personal Data is a
statutory or contractual requirement, or a requirement necessary to enter into
a contract.{" "}
						</Span>
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Place
						</Strong>
						<br />
						<Span
							hyphens="manual"
							text-overflow="clip"
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
						>
							The Data is processed at
the Owner's operating offices and in any other places where the parties
involved in the processing are located.
						</Span>
						<br />
						<br />
						<Span
							hyphens="manual"
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
						>
							Depending on the User's
location, data transfers may involve transferring the User's Data to a country
other than their own. To find out more about the place of processing of such
transferred Data, Users can check the section containing details about the
processing of Personal Data.
							<br />
							<br />
							Users are also entitled
to learn about the legal basis of Data transfers to a country outside the
European Union or to any international organisation governed by public
international law or set up by two or more countries, such as the UN, and about
the security measures taken by the Owner to safeguard their Data.
							<br />
						</Span>
						<br />
						<Span
							font-weight="normal"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
						>
							If any such transfer
takes place, Users can find out more by checking the relevant sections of this
document or enquire with the Owner using the information provided in the
contact section.
						</Span>
						<br />
						<br />
						<Strong
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
						>
							Retention time
						</Strong>
						<br />
						<Span
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							hyphens="manual"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
						>
							Personal Data shall be
processed and stored for as long as required by the purpose they have been
collected for.
						</Span>
						<br />
						<br />
						<Span
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
						>
							Therefore:
							<br />
							• Personal Data collected for purposes related to the performance of a
contract between the Owner and the User shall be retained until such contract
has been fully performed.
							<br />
							• Personal Data collected for the purposes of the Owner’s legitimate
interests shall be retained as long as needed to fulfil such purposes. Users
may find specific information regarding the legitimate interests pursued by the
Owner within the relevant sections of this document or by contacting the Owner.
						</Span>
						<br />
						<br />
						<Span
							overflow-wrap="normal"
							white-space="normal"
							text-overflow="clip"
							font-weight="normal"
							word-break="normal"
							text-indent="0"
							hyphens="manual"
						>
							The Owner may be allowed
to retain Personal Data for a longer period whenever the User has given consent
to such processing, as long as such consent is not withdrawn. Furthermore, the
Owner may be obliged to retain Personal Data for a longer period whenever
required to do so for the performance of a legal obligation or upon order of an
authority.
							<br />
							{" "}
							<br />
							Once the retention period
expires, Personal Data shall be deleted. Therefore, the right of access, the
right to erasure, the right to rectification and the right to data portability
cannot be enforced after expiration of the retention period.
						</Span>
						<br />
						<br />
						<Strong
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
						>
							The rights of Users
						</Strong>
						<br />
						<Span
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							text-indent="0"
							white-space="normal"
							text-overflow="clip"
							hyphens="manual"
						>
							Users may exercise
certain rights regarding their Data processed by the Owner.
							<br />
							{" "}
							<br />
							In particular, Users have
the right to do the following:
						</Span>
						<br />
						•{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Withdraw their consent at any time.
						</Strong>
						{" "}
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-overflow="clip"
							font-weight="normal"
							text-indent="0"
							hyphens="manual"
						>
							Users have the right
to withdraw consent where they have previously given their consent to the
processing of their Personal Data.
						</Span>
						<br />
						•{" "}
						<Strong
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
						>
							Object to processing of their Data.
						</Strong>
						{" "}
						<Span
							text-overflow="clip"
							hyphens="manual"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							font-weight="normal"
							overflow-wrap="normal"
						>
							Users have the right
to object to the processing of their Data if the processing is carried out on a
legal basis other than consent. Further details are provided in the dedicated
section below.
						</Span>
						<br />
						•{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Access their Data.
						</Strong>
						{" "}
						<Span
							text-overflow="clip"
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							hyphens="manual"
						>
							Users have the right to learn if Data
is being processed by the Owner, obtain disclosure regarding certain aspects of
the processing and obtain a copy of the Data undergoing processing.
						</Span>
						<br />
						•{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Verify and seek rectification.
						</Strong>
						{" "}
						<Span
							white-space="normal"
							text-indent="0"
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							text-overflow="clip"
							hyphens="manual"
						>
							Users have the right to
verify the accuracy of their Data and ask for it to be updated or corrected.
						</Span>
						<br />
						•{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Restrict the processing of their Data.
						</Strong>
						{" "}
						<Span
							text-indent="0"
							hyphens="manual"
							white-space="normal"
							text-overflow="clip"
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
						>
							Users have the
right, under certain circumstances, to restrict the processing of their Data.
In this case, the Owner will not process their Data for any purpose other than
storing it.
						</Span>
						<br />
						•{" "}
						<Strong
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
						>
							Have their Personal Data deleted or otherwise removed.
						</Strong>
						{" "}
						<Span
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							font-weight="normal"
							word-break="normal"
							overflow-wrap="normal"
							hyphens="manual"
						>
							Users
have the right, under certain circumstances, to obtain the erasure of their
Data from the Owner.
						</Span>
						<br />
						•{" "}
						<Strong
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
						>
							Receive their Data and have it transferred to another controller.
						</Strong>
						{" "}
						<Span
							overflow-wrap="normal"
							white-space="normal"
							text-overflow="clip"
							hyphens="manual"
							font-weight="normal"
							word-break="normal"
							text-indent="0"
						>
							Users
have the right to receive their Data in a structured, commonly used and machine
readable format and, if technically feasible, to have it transmitted to another
controller without any hindrance. This provision is applicable provided that
the Data is processed by automated means and that the processing is based on
the User's consent, on a contract which the User is part of or on
pre-contractual obligations thereof.
						</Span>
						<br />
						•{" "}
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Lodge a complaint.
						</Strong>
						{" "}
						<Span
							font-weight="normal"
							word-break="normal"
							text-indent="0"
							overflow-wrap="normal"
							white-space="normal"
							text-overflow="clip"
							hyphens="manual"
						>
							Users have the right to bring a claim
before their competent data protection authority.
						</Span>
						<br />
						<br />
						<Strong
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
						>
							Details about the right
to object to processing
						</Strong>
						<br />
						<Span
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							font-weight="normal"
						>
							Where Personal Data is
processed for a public interest, in the exercise of an official authority
vested in the Owner or for the purposes of the legitimate interests pursued by
the Owner, Users may object to such processing by providing a ground related to
their particular situation to justify the objection.
						</Span>
						<br />
						<br />
						<Span
							font-weight="normal"
							white-space="normal"
							text-indent="0"
							overflow-wrap="normal"
							word-break="normal"
							text-overflow="clip"
							hyphens="manual"
						>
							Users must know that,
however, should their Personal Data be processed for direct marketing purposes,
they can object to that processing at any time without providing any
justification. To learn, whether the Owner is processing Personal Data for
direct marketing purposes, Users may refer to the relevant sections of this
document.{" "}
						</Span>
						<br />
						{" "}
						<br />
						<Strong
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
						>
							How to exercise these
rights
						</Strong>
						<br />
						<Span
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							hyphens="manual"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
						>
							Any requests to exercise
User rights can be directed to the Owner through the contact details provided
in this document. These requests can be exercised free of charge and will be
addressed by the Owner as early as possible and always within one month.
							<br />
							{" "}
						</Span>
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Personal Data collected
for the following purposes and using the following services:{" "}
						</Strong>
						<Span
							hyphens="manual"
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
						>
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
							>
								Hosting and backend
infrastructure{" "}
							</Span>
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
							>
								Amazon Web Services (AWS)
West Region (Oregon, US).
							</Span>
						</Span>
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Personal Data:
						</Strong>
						{" "}
						<Span
							overflow-wrap="normal"
							white-space="normal"
							font-weight="normal"
							word-break="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							various
types of Data as specified in the privacy policy of the service.
						</Span>
						<br />
						<br />
						<Strong
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
						>
							Cookie Policy
						</Strong>
						<br />
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							hyphens="manual"
							font-weight="normal"
							text-overflow="clip"
						>
							This Application uses
Trackers.
							<br />
							{" "}
							<br />
							This paragraph informs
Users about the technologies that help this Application to achieve the purposes
described below. Such technologies allow the Owner to access and store
information (for example by using a Cookie) or use resources (for example by
running a script) on a User’s device as they interact with this Application.
						</Span>
						<br />
						{" "}
						<br />
						<Span
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							hyphens="manual"
							font-weight="normal"
							text-overflow="clip"
						>
							For simplicity, all such
technologies are defined as "Trackers" within this document – unless
there is a reason to differentiate.
							<br />
							<br />
							For example, while
Cookies can be used on both web and mobile browsers, it would be inaccurate to
talk about Cookies in the context of mobile apps as they are a browser-based
Tracker. For this reason, within this document, the term Cookies is only used
where it is specifically meant to indicate that particular type of Tracker.
							<br />
							<br />
							Some of the purposes for
which Trackers are used may also require the User's consent. Whenever consent
is given, it can be freely withdrawn at any time following the instructions
provided in this document.
						</Span>
						<br />
						<br />
						<Span
							word-break="normal"
							white-space="normal"
							hyphens="manual"
							overflow-wrap="normal"
							text-indent="0"
							text-overflow="clip"
							font-weight="normal"
						>
							This Application only
uses Trackers managed directly by the Owner (so-called “first-party” Trackers).
							<br />
							<br />
							The validity and
expiration periods of first-party Cookies and other similar Trackers may vary
depending on the lifetime set by the Owner. Some of them expire upon
termination of the User’s browsing session.
						</Span>
						<br />
						{" "}
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Activities strictly
necessary for the operation of this Application and delivery of the Service
						</Strong>
						<br />
						<Span
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							This Application uses
so-called “technical” Cookies and other similar Trackers to carry out
activities that are strictly necessary for the operation or delivery of the
Service.
						</Span>
						<br />
						<br />
						<Strong
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
						>
							How to manage preferences
and provide or withdraw consent
						</Strong>
						<br />
						<Span
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							There are various ways to
manage Tracker related preferences and to provide and withdraw consent, where
relevant:{" "}
							<br />
							<br />
							Users can manage
preferences related to Trackers from directly within their own device settings,
for example, by preventing the use or storage of Trackers.
						</Span>
						<br />
						<br />
						<Span
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
						>
							Additionally, whenever
the use of Trackers is based on consent, Users can provide or withdraw such
consent by setting their preferences within the cookie notice or by updating
such preferences accordingly via the relevant consent-preferences widget, if
available.
							<br />
							<br />
							It is also possible, via
relevant browser or device features, to delete previously stored Trackers,
including those used to remember the User’s initial consent.
							<br />
							<br />
							Other Trackers in the
browser’s local memory may be cleared by deleting the browsing history.
						</Span>
						<br />
						<br />
						<Span
							overflow-wrap="normal"
							text-overflow="clip"
							hyphens="manual"
							font-weight="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
						>
							<Em
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								overflow-wrap="normal"
							>
								Locating Tracker Settings
							</Em>
							<br />
							<br />
							Users can, for example,
find information about how to manage Cookies in the most commonly used browsers
at the following addresses:
							<br />
							•
							<Link
								href="https://support.google.com/chrome/answer/95647?hl=en&p=cpn_cookies"
								text-indent="0"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-overflow="clip"
								hyphens="manual"
							>
								Google Chrome
							</Link>
							<br />
							•
							<Link
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								overflow-wrap="normal"
								word-break="normal"
								href="https://support.mozilla.org/en-US/kb/enable-and-disable-cookies-website-preferences"
							>
								Mozilla Firefox
							</Link>
							<br />
							•
							<Link
								href="https://support.apple.com/guide/safari/manage-cookies-and-website-data-sfri11471/"
								white-space="normal"
								text-indent="0"
								hyphens="manual"
								overflow-wrap="normal"
								word-break="normal"
								text-overflow="clip"
							>
								Apple Safari
							</Link>
							<br />
							•
							<Link
								href="http://windows.microsoft.com/en-us/windows-vista/block-or-allow-cookies"
								overflow-wrap="normal"
								text-overflow="clip"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								hyphens="manual"
							>
								Microsoft Internet
Explorer
							</Link>
							<br />
							•
							<Link
								word-break="normal"
								text-indent="0"
								hyphens="manual"
								href="https://support.microsoft.com/en-us/help/4027947"
								overflow-wrap="normal"
								white-space="normal"
								text-overflow="clip"
							>
								Microsoft Edge
							</Link>
							<br />
							•
							<Link
								overflow-wrap="normal"
								word-break="normal"
								text-indent="0"
								href="https://support.brave.com/hc/en-us/articles/360022806212-How-do-I-use-Shields-while-browsing"
								white-space="normal"
								text-overflow="clip"
								hyphens="manual"
							>
								Brave
							</Link>
							<br />
							•
							<Link
								href="https://help.opera.com/en/latest/web-preferences/"
								white-space="normal"
								text-overflow="clip"
								overflow-wrap="normal"
								word-break="normal"
								text-indent="0"
								hyphens="manual"
							>
								Opera
							</Link>
						</Span>
						<br />
						<br />
						<Span
							text-overflow="clip"
							hyphens="manual"
							font-weight="normal"
							word-break="normal"
							text-indent="0"
							overflow-wrap="normal"
							white-space="normal"
						>
							Users may also manage
certain categories of Trackers used on mobile apps by opting out through
relevant device settings, such as the device advertising settings for mobile
devices, or tracking settings in general (Users may open the device settings,
view and look for the relevant setting).
						</Span>
						<br />
						<br />
						<Strong
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
						>
							Owner and Data Controller
						</Strong>
						<br />
						<Span
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							hyphens="manual"
						>
							Given the objective
complexity surrounding tracking technologies, Users are encouraged to contact
the Owner should they wish to receive any further information on the use of
such technologies by this Application.
						</Span>
						<br />
						<br />
						<Strong
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
						>
							Additional information
about Data collection and processing
						</Strong>
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Legal action
						</Strong>
						<br />
						<Span
							overflow-wrap="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							font-weight="normal"
							word-break="normal"
							hyphens="manual"
						>
							The User's Personal Data
may be used for legal purposes by the Owner in Court or in the stages leading
to possible legal action arising from improper use of this Application or the
related Services.
						</Span>
						<br />
						<br />
						<Span
							font-weight="normal"
							word-break="normal"
							overflow-wrap="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							The User declares to be
aware that the Owner may be required to reveal person
						</Span>
						al data upon request of
public authorities.
						<br />
						<br />
						<Strong
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
						>
							Additional information
about User's Personal Data
						</Strong>
						<br />
						<Span
							font-weight="normal"
							overflow-wrap="normal"
							text-indent="0"
							hyphens="manual"
							word-break="normal"
							white-space="normal"
							text-overflow="clip"
						>
							In addition to the
information contained in this privacy policy, this Application may provide the
User with additional and contextual information concerning particular Services
or the collection and processing of Personal Data upon request.
						</Span>
						<br />
						<br />
						<Span
							font-weight="normal"
							overflow-wrap="normal"
							hyphens="manual"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
						>
							<Span
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
							>
								System logs and
maintenance
							</Span>
							<br />
							For operation and
maintenance purposes, this Application and any third-party services may collect
files that record interaction with this Application (System logs) use other
Personal Data (such as the IP Address) for this purpose.
							<br />
							<br />
							<Span
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
								overflow-wrap="normal"
								word-break="normal"
							>
								Information not contained
in this policy
							</Span>
							<br />
							More details concerning
the collection or processing of Personal Data may be requested from the Owner
at any time. Please see the contact information at the beginning of this
document.
							<br />
							<br />
							<Span
								overflow-wrap="normal"
								word-break="normal"
								white-space="normal"
								text-indent="0"
								text-overflow="clip"
								hyphens="manual"
							>
								How “Do Not Track”
requests are handled
							</Span>
							<br />
							This Application does not
support “Do Not Track” requests.
						</Span>
						<br />
						<br />
						<Span
							font-weight="normal"
							word-break="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							white-space="normal"
						>
							To determine whether any
of the third-party services it uses honour the “Do Not Track” requests, please
read their privacy policies.
						</Span>
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Changes to this privacy
policy
						</Strong>
						<br />
						<Span
							overflow-wrap="normal"
							white-space="normal"
							text-overflow="clip"
							hyphens="manual"
							font-weight="normal"
							word-break="normal"
							text-indent="0"
						>
							The Owner reserves the
right to make changes to this privacy policy at any time by notifying its Users
on this page and possibly within this Application and/or - as far as
technically and legally feasible - sending a notice to Users via any contact
information available to the Owner. It is strongly recommended to check this page
often, referring to the date of the last modification listed at the
bottom.{" "}
						</Span>
						<br />
						<br />
						<Span
							font-weight="normal"
							word-break="normal"
							white-space="normal"
							overflow-wrap="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Should the changes affect
processing activities performed on the basis of the User’s consent, the Owner
shall collect new consent from the User, where required.
							<br />
						</Span>
						<br />
						<Strong
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
						>
							Definitions and legal
references
						</Strong>
						<br />
						<br />
						<Strong
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
						>
							Personal Data (or Data)
						</Strong>
						<br />
						<Span
							white-space="normal"
							text-overflow="clip"
							hyphens="manual"
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							text-indent="0"
						>
							Any information that
directly, indirectly, or in connection with other information — including a
personal identification number — allows for the identification or
identifiability of a natural person.
							<br />
						</Span>
						<br />
						<Strong
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
						>
							Usage Data
						</Strong>
						<br />
						<Span
							hyphens="manual"
							font-weight="normal"
							overflow-wrap="normal"
							white-space="normal"
							text-indent="0"
							word-break="normal"
							text-overflow="clip"
						>
							Information collected
automatically through this Application (or third-party services employed in
this Application), which can include: the IP addresses or domain names of the
computers utilised by the Users who use this Application, the URI addresses
(Uniform Resource Identifier), the time of the request, the method utilised to
submit the request to the server, the size of the file received in response,
the numerical code indicating the status of the server's answer (successful
outcome, error, etc.), the country of origin, the features of the browser and
the operating system utilised by the User, the various time details per visit
(e.g., the time spent on each page within the Application) and the details
about the path followed within the Application with special reference to the
sequence of pages visited, and other parameters about the device operating
system and/or the User's IT environment.
						</Span>
						<br />
						<br />
						<Strong
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
						>
							User
						</Strong>
						<br />
						<Span
							font-weight="normal"
							white-space="normal"
							text-indent="0"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							text-overflow="clip"
						>
							The individual using this
Application who, unless otherwise specified, coincides with the Data Subject.
							<br />
						</Span>
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Data Subject
						</Strong>
						<br />
						<Span
							font-weight="normal"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							The natural person to
whom the Personal Data refers.
						</Span>
						<br />
						<br />
						<Strong
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							Data Processor (or Data
Supervisor)
						</Strong>
						<br />
						<Span
							hyphens="manual"
							word-break="normal"
							text-indent="0"
							white-space="normal"
							text-overflow="clip"
							font-weight="normal"
							overflow-wrap="normal"
						>
							The natural or legal
person, public authority, agency or other body which processes Personal Data on
behalf of the Controller, as described in this privacy policy.
						</Span>
						<br />
						<br />
						<Strong
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
						>
							Data Controller (or
Owner)
						</Strong>
						<br />
						<Span
							overflow-wrap="normal"
							white-space="normal"
							font-weight="normal"
							word-break="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
						>
							The natural or legal
person, public authority, agency or other body which, alone or jointly with
others, determines the purposes and means of the processing of Personal Data,
including the security measures concerning the operation and use of this
Application. The Data Controller, unless otherwise specified, is the Owner of
this Application.
						</Span>
						<br />
						<br />
						<Strong
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
						>
							This Application
						</Strong>
						<br />
						<Span
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							font-weight="normal"
							overflow-wrap="normal"
							hyphens="manual"
						>
							The means by which the
Personal Data of the User is collected and processed.
						</Span>
						<br />
						<br />
						<Strong
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
						>
							Service
						</Strong>
						<br />
						<Span
							overflow-wrap="normal"
							white-space="normal"
							text-overflow="clip"
							hyphens="manual"
							font-weight="normal"
							word-break="normal"
							text-indent="0"
						>
							The service provided by
this Application as described in the relative terms (if available) and on this
site/application.
						</Span>
						<br />
						<br />
						<Strong
							word-break="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
						>
							European Union (or EU)
						</Strong>
						<br />
						<Span
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							text-indent="0"
							font-weight="normal"
							white-space="normal"
						>
							Unless otherwise
specified, all references made within this document to the European Union
include all current member states to the European Union and the European
Economic Area.
							<br />
						</Span>
						<br />
						<Strong
							text-indent="0"
							text-overflow="clip"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
						>
							Legal information
						</Strong>
						<br />
						<Span
							overflow-wrap="normal"
							white-space="normal"
							text-indent="0"
							text-overflow="clip"
							font-weight="normal"
							word-break="normal"
							hyphens="manual"
						>
							This privacy statement
has been prepared based on provisions of multiple legislations, including Art.
13/14 of Regulation (EU) 2016/679 (General Data Protection Regulation).
						</Span>
						<br />
						{" "}
						<br />
						<Span
							text-overflow="clip"
							font-weight="normal"
							white-space="normal"
							text-indent="0"
							hyphens="manual"
							overflow-wrap="normal"
							word-break="normal"
						>
							This privacy policy
relates solely to this Application, if not stated otherwise within this
document.
						</Span>
						<br />
						<br />
						<Span
							font-weight="normal"
							hyphens="manual"
							text-indent="0"
							text-overflow="clip"
							overflow-wrap="normal"
							word-break="normal"
							white-space="normal"
						>
							For more information about our privacy practices, if
you have questions, or if you would like to make a complaint, please contact us
by e-mail at{" "}
							<Link
								white-space="normal"
								text-indent="0"
								href="http://www.ventatrans.com"
								overflow-wrap="normal"
								word-break="normal"
								text-overflow="clip"
								hyphens="manual"
							>
								www.ventatrans.com
							</Link>
							{" "}or by post
using the details provided below:
							<br />
							<br />
							Venta - Trans Europe s.r.o.
							<br />
							Weena 505, 3013 AL
Rotterdam.  The Netherlands
							<br />
							<br />
							Last
updated on 01.11.2021
						</Span>
					</Text>
					<Components.QuarklycommunityKitBackToTop showAlways easingPreset="easeInOutQuad">
						<Override slot="Button" href="/4041#top" target="_top" />
					</Components.QuarklycommunityKitBackToTop>
				</StackItem>
			</Stack>
		</Section>
		<Section background="--color-logo" quarkly-title="Footer">
			<Override slot="SectionContent" sm-margin="0px auto 0px auto" />
			<Box justify-content="center" display="flex" sm-font="--base">
				<Text
					color="--fonText"
					sm-font="--text11Mob"
					sm-padding="0px 0px 0px 0px"
					padding="0px 10px 0px 0px"
					font="--lead"
					display="block"
					margin="16px 0px 16px 0"
				>
					© 2021 Venta-Trans Europe.
				</Text>
				<Text
					color="--fonText"
					sm-font="--text11Mob"
					sm-padding="0px 0px 0px 0"
					font="--lead"
					display="block"
					margin="16px 0px 16px 0"
				>
					All Rights Reserved.
				</Text>
			</Box>
		</Section>
		<Components.Cookies>
			<Override slot="Text" font="--baseMob" margin="16px 80px 16px 80px">
				This website uses cookies to provide you with a better online
experience. If you select "Accept" or access any content of our
website without customising your choices, you agree to the use of cookies.{" "}
				<Link
					href="/privacypolicy"
					word-break="normal"
					target="_self"
					overflow-wrap="normal"
					white-space="normal"
					text-indent="0"
					text-overflow="clip"
					hyphens="manual"
					color="--fon2"
				>
					Find
out more about our Privacy & Cookie Policy
				</Link>
				.
			</Override>
			<Override slot="Button" font="--baseMob" />
		</Components.Cookies>
		<RawHtml>
			<style place={"endOfBody"} rawKey={"608142d6ab4dd832c0755f1a"}>
				{"a[href=\"https://quarkly.io/\"]{\ndisplay: none;\n}"}
			</style>
			<script src={"https://www.googletagmanager.com/gtag/js?id=G-118BTS02HM"} async={true} place={"endOfHead"} rawKey={"6082f9b76cd311d618e2da67"} />
			<script place={"endOfHead"} rawKey={"6082f9d0a29b8aa4421cb038"}>
				{"window.dataLayer = window.dataLayer || [];\nfunction gtag(){dataLayer.push(arguments);}\ngtag('js', new Date());\ngtag('config', 'G-118BTS02HM');"}
			</script>
			<script src={"https://fast.wistia.com/embed/medias/ivn4prep36.jsonp"} async={true} place={"endOfHead"} rawKey={"6083012406cc56de18db89d6"} />
			<script async={true} src={"https://fast.wistia.com/assets/external/E-v1.js"} place={"endOfHead"} rawKey={"608301398ecb4f30a2c73278"} />
		</RawHtml>
	</Theme>;
});